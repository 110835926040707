import './style.scss';

import { IconButton } from 'app/components/IconButton';
import FlagIcon from 'assets/icons/flagIcon.svg';
import SearchIcon from 'assets/icons/search.svg';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageModal } from '../LanguageModal';
import SearchInput from '../SearchInput';

export function NavBar() {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <nav className="rounded-2xl bg-dark-blue">
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex w-3/4">
            <form className="w-full max-w-md">
              <div className="relative">
                <SearchInput
                  value={searchValue}
                  onChange={setSearchValue}
                  iconPosition="right"
                  placeholder={t('sideBar.searchForAnything')}
                  icon={SearchIcon}
                  inputClassName="header-search py-3 h-10 w-96 border-none rounded-lg bg-blue-violet p-4 pr-10 text-sm text-white"
                />
              </div>
            </form>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative ml-3">
              <IconButton
                src={FlagIcon}
                alt="iconFrame"
                ariaLabel="View"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
            <LanguageModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
