import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from 'utils/commonFunction';

interface DatePickerSectionProps {
  checkInDate: string | null;
  checkOutDate: string | null;
  setCheckInDate: (date: string | null) => void;
  setCheckOutDate: (date: string | null) => void;
  isCheckInPickerOpen: boolean;
  setIsCheckInPickerOpen: (open: boolean) => void;
  isCheckOutPickerOpen: boolean;
  setIsCheckOutPickerOpen: (open: boolean) => void;
  disabledDates: (date: Dayjs) => boolean;
  disabledDatesCheckIn: (date: Dayjs) => boolean;
  availabilityDates: { start_datetime?: string; end_datetime?: string }[];
}
const renderAvailabilityDates = (
  availabilityDates: { start_datetime?: string; end_datetime?: string }[],
  key: 'start_datetime' | 'end_datetime',
) => {
  return availabilityDates.length > 0
    ? availabilityDates.map((availability, index) => (
        <div key={index}>
          {availability[key]
            ? formatDateTime(availability[key] || '', 'YYYY-MM-DD HH:mm')
            : 'null'}
        </div>
      ))
    : null;
};

const DatePickerCart: React.FC<DatePickerSectionProps> = ({
  checkInDate,
  checkOutDate,
  setCheckInDate,
  setCheckOutDate,
  isCheckInPickerOpen,
  setIsCheckInPickerOpen,
  isCheckOutPickerOpen,
  setIsCheckOutPickerOpen,
  disabledDates,
  availabilityDates,
  disabledDatesCheckIn,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 flex h-[60px] w-full cursor-pointer flex-row ">
      <div className="ml-5 flex-1 border-r border-gray-500">
        <span className="text-[14px] font-semibold">
          {t('product_detail.Check_In')}
        </span>
        <div className="text-[14px] ">
          {checkInDate ||
            renderAvailabilityDates(availabilityDates, 'start_datetime')}

          {isCheckInPickerOpen && (
            <DatePicker
              open={isCheckInPickerOpen}
              showTime
              format="YYYY-MM-DD HH:mm "
              onChange={date => {
                setCheckInDate(date?.format('YYYY-MM-DD HH:mm') || null);
                setIsCheckInPickerOpen(false);
              }}
              onOpenChange={open => {
                if (!open) setIsCheckInPickerOpen(false);
              }}
              style={{ visibility: 'hidden', width: 0 }}
              disabledDate={disabledDates}
            />
          )}
        </div>
      </div>

      <div className="ml-5 flex-1">
        <span className="text-[14px] font-semibold">
          {t('product_detail.Check_Out')}
        </span>
        <div className="text-[14px] ">
          {checkOutDate ||
            renderAvailabilityDates(availabilityDates, 'end_datetime')}
          {isCheckOutPickerOpen && (
            <DatePicker
              open={isCheckOutPickerOpen}
              showTime
              format="YYYY-MM-DD HH:mm"
              onChange={date => {
                setCheckOutDate(date?.format('YYYY-MM-DD HH:mm') || null);
                setIsCheckOutPickerOpen(false);
              }}
              onOpenChange={open => {
                if (!open) setIsCheckOutPickerOpen(false);
              }}
              style={{ visibility: 'hidden', width: 0 }}
              disabledDate={disabledDatesCheckIn}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DatePickerCart;
