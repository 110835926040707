import { useGetProductId } from 'hooks/queries';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import type { ProductTypeFormData } from 'types/entities';

import AddProductListing from '../product-listing';

const UpdateListing = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: selectedProduct, isLoading } = useGetProductId(uuid || '');
  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <AddProductListing
          updateData={selectedProduct as unknown as ProductTypeFormData}
        />
      )}
    </div>
  );
};

export default UpdateListing;
