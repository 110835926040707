'use client';

import { type ChangeEvent, useState } from 'react';

import Loader from '../Loader';
import AddIcon from './../../../assets/icons/addIcon2.svg';

interface ImageUploaderProps {
  onImageUpload: (file: File) => void;
  previewSrc?: string | null;
  accept?: string;
  width?: number;
  height?: number;
  addIconClassName?: string;
  AddIconWidth?: number;
  AddIconHeight?: number;
  MainContainerWidth?: string;
  MainContainerHeight?: string;
  alt?: string;
}

const Uploader: React.FC<ImageUploaderProps> = ({
  onImageUpload,
  accept = 'image/*',
  addIconClassName,
  AddIconWidth = 20,
  AddIconHeight = 20,
  MainContainerWidth = '77px',
  MainContainerHeight = '33px',
  previewSrc,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.files && event.target.files[0]) {
      setIsLoading(true);
      const file = event.target.files[0];
      setTimeout(() => {
        onImageUpload(file);
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <div
      style={{
        width: MainContainerWidth,
        height: MainContainerHeight,
      }}
      className="relative flex cursor-pointer flex-col items-center justify-center border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <input
        type="file"
        id="dropzone-file"
        onChange={handleImageChange}
        name="myfile"
        accept={accept}
        multiple
        className="absolute inset-0 size-full opacity-0"
        aria-label="Upload images"
      />
      {isLoading ? (
        <Loader />
      ) : previewSrc ? (
        <img
          src={previewSrc}
          alt="Preview"
          className="size-full object-cover"
        />
      ) : (
        <div className="flex items-center justify-center">
          <img
            src={AddIcon}
            alt="Add"
            style={{ width: AddIconWidth, height: AddIconHeight }}
            className={addIconClassName || ''}
          />
        </div>
      )}
    </div>
  );
};

export default Uploader;
