import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import DeleteIcon from './../../../assets/icons/deleteIcon.svg';

const { RangePicker } = DatePicker;

interface SchedulingTableProps {
  data: {
    key: number;
    startDateTime: string;
    endDateTime: string;
  }[];
  onDelete: (key: number) => void;
}

const SchedulingTable: React.FC<SchedulingTableProps> = ({
  data,
  onDelete,
}) => {
  return (
    <div className="my-2 flex w-full flex-col items-center">
      {data.length === 0 ? (
        <div className="py-10 text-center text-gray-500">
          No schedules added.
        </div>
      ) : (
        <div className="w-full max-w-full space-y-2">
          {' '}
          {data.map(item => {
            const startDate = dayjs(item.startDateTime);
            const endDate = dayjs(item.endDateTime);
            return (
              <div
                key={item.key}
                className="flex items-center gap-24 space-x-2"
              >
                <div className="sm:w-1/3">
                  <RangePicker
                    value={[startDate, endDate]}
                    format="YYYY-MM-DD"
                    className="w-[19rem]"
                  />
                </div>
                <div className="sm:w-1/4">
                  <TimePicker.RangePicker
                    value={[startDate, endDate]}
                    format="h:mm A"
                    className="w-[17rem]"
                  />
                </div>
                <div className="flex justify-center sm:w-auto">
                  <div
                    className="ml-4 cursor-pointer text-red-600 hover:text-red-800"
                    onClick={() => onDelete(item.key)}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete Icon"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SchedulingTable;
