import { DownOutlined } from '@ant-design/icons';
import { Checkbox, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ServiceIcon from '../../../assets/icons/serviceIcon.svg';
interface Option {
  label: string;
  value: any;
  iconUrl?: string;
}
interface MultiSelectProps {
  id: string;
  options: Option[];
  label: string;
  placeholder?: string;
  onChange: (selectedItems: string[]) => void;
  selectedItems: string[];
  containerClassName?: string;
  errors: any;
  disabled?: boolean;
}
const MultiSelect: React.FC<MultiSelectProps> = ({
  id,
  options,
  label,
  placeholder,
  onChange,
  selectedItems = [],
  containerClassName = '',
  errors,
  disabled = false,
}) => {
  const handleSelectChange = (value: string[]) => {
    onChange(value);
  };
  const { t } = useTranslation();
  const handleCheckboxChange = (value: string) => {
    const newSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter(item => item !== value)
      : [...selectedItems, value];
    onChange(newSelectedItems);
  };

  return (
    <div className={containerClassName}>
      <label
        htmlFor={id}
        className="mb-2 block text-[14px] font-normal text-gray-900"
      >
        {label}
        <span className="text-red-600">*</span>
      </label>
      <Select
        id={id}
        disabled={disabled}
        size="large"
        mode="multiple"
        value={selectedItems}
        placeholder={placeholder || 'placeholder'}
        onChange={handleSelectChange}
        optionLabelProp="label"
        className="w-full rounded-lg border shadow-md"
        suffixIcon={<DownOutlined />}
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
          >
            <div className="flex items-center space-x-3">
              <Checkbox
                checked={selectedItems.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              <img
                src={option.iconUrl || ServiceIcon}
                alt={'option_icon'}
                className="size-4"
              />
              <span className="text-[14px]">{option.label}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
      {errors?.title && (
        <p className="text-red-600">
          {' '}
          {t('ProductListing.Please_select_product_type')}
        </p>
      )}
    </div>
  );
};
export default MultiSelect;
