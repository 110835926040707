import { Form } from 'antd';
import CustomInput from 'app/components/Input';
import Uploader from 'app/components/Uploader';
import { useUploadImage } from 'hooks/queries';
import React, { useState } from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ProductVariant } from 'types/entities';

import deleteIcon from './../../../assets/icons/deleteIcon.svg';

interface VariantsTableProps {
  variants: ProductVariant[];
  setValue: UseFormSetValue<any>;
  index: number;
  onRemoveVariant: (index: number) => void;
}

const VariantsTable: React.FC<VariantsTableProps> = ({
  variants,
  index,
  onRemoveVariant,
  setValue,
}) => {
  const { mutate: uploadImage } = useUploadImage();
  const [uploadedImages, setUploadedImages] = useState<{
    [key: number]: string;
  }>({});
  const { t } = useTranslation();

  const handleImageUpload = (file: File, variantIndex: number) => {
    const formData = new FormData();
    formData.append('files', file);

    uploadImage(formData, {
      onSuccess: (data: { uploaded_documents: { url: any }[] }) => {
        const imageUrl = data?.uploaded_documents?.[0]?.url;
        if (imageUrl) {
          setValue(
            `product_details[${index}].variants[${variantIndex}].variants_values[0].image`,
            imageUrl,
          );
          setUploadedImages(prev => ({
            ...prev,
            [variantIndex]: imageUrl,
          }));
        }
      },
    });
  };

  const activeVariants = variants.filter(
    variant => !variant.variants_values?.[0]?.is_deleted,
  );

  return (
    <div className="my-10 flex w-full justify-center overflow-x-auto">
      {activeVariants.length === 0 ? (
        <div className="py-10 text-center text-gray-500">
          {t('ProductListing.no_variants')}
        </div>
      ) : (
        <div className="w-[95%] max-w-4xl bg-gray-100">
          <table className="min-w-full border-collapse text-center">
            <thead>
              <tr className="text-center">
                <th className="px-6 py-2 text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.image')}
                </th>
                <th className="px-4 py-2 text-left text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.name')}
                </th>
                <th className="px-4 py-2 text-center text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.variants')}
                </th>
                <th className="px-4 py-2 text-center text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.price')}
                </th>
                <th className="px-4 py-2 text-left text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.in-stock')}
                </th>
                <th className="px-4 py-2 text-left text-[10px] font-medium sm:text-[12px]">
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-light-gray-300 divide-y divide-gray-200 text-[10px] font-medium sm:text-[12px]">
              {activeVariants.map((variant, variantIndex) => (
                <tr
                  key={variant.id || variantIndex}
                  className="bg-light-gray-500 divide-y border-b border-t-0 border-gray-200 text-left"
                >
                  <td className="rounded-es-lg rounded-ss-lg px-4 py-2 sm:px-4">
                    <Uploader
                      onImageUpload={file =>
                        handleImageUpload(file, variantIndex)
                      }
                      height={40}
                      previewSrc={
                        uploadedImages[variantIndex] ||
                        variant.variants_values?.[0]?.image
                      } // Check for locally uploaded images first
                      addIconClassName="flex justify-center"
                      MainContainerHeight="60px"
                    />
                  </td>
                  <td className="px-4 py-2">{variant.name}</td>
                  <td className="px-4 py-2">
                    {variant.variants_values?.[0]?.value}
                  </td>
                  <td className="px-4">
                    <Form.Item>
                      <CustomInput
                        type="number"
                        height="36px"
                        containerClassName="relative mt-4 flex items-center w-full "
                        inputClassName="focus:outline-none mt-2 w-full "
                        valueAsNumber
                        defaultValue={variant.variants_values?.[0]?.price}
                        name={`product_details[${index}].variants[${variantIndex}].variants_values[0].price`}
                      />
                    </Form.Item>
                  </td>
                  <td className="px-4 ">
                    <Form.Item>
                      <CustomInput
                        type="number"
                        height="36px"
                        containerClassName="relative mt-4 flex items-center "
                        inputClassName="focus:outline-none mt-2 w-full "
                        valueAsNumber
                        defaultValue={variant.variants_values?.[0]?.stock}
                        name={`product_details[${index}].variants[${variantIndex}].variants_values[0].stock`}
                      />
                    </Form.Item>
                  </td>
                  <td className="px-4 py-2">
                    <div
                      onClick={() => onRemoveVariant(variantIndex)}
                      className="text-red-500 hover:text-red-700"
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={deleteIcon}
                        alt={'delete'}
                        width={20}
                        height={20}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default VariantsTable;
