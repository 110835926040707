import { DatePicker, TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddIcon from './../../../assets/icons/addIcon2.svg';
import SchedulingTable from './SchedualingTable';

const { RangePicker } = DatePicker;

interface SchedulingProps {
  setValue: (path: string, value: any) => void;
  index: number;
}

interface ScheduleEntry {
  key: number;
  startDateTime: string;
  endDateTime: string;
}

const Scheduling: React.FC<SchedulingProps> = ({ setValue, index }) => {
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [times, setTimes] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [scheduleEntries, setScheduleEntries] = useState<ScheduleEntry[]>([]);
  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const scheduleType = 'HOURLY';
  const { t } = useTranslation();

  const resetFields = () => {
    setDates(null);
    setTimes(null);
    setValue(`product_details[${index}].availability.start_date`, null);
    setValue(`product_details[${index}].availability.end_date`, null);
    setValue(`product_details[${index}].availability.start_time`, null);
    setValue(`product_details[${index}].availability.end_time`, null);
  };
  const updateAvailability = (entries: ScheduleEntry[]) => {
    const availabilityPayload = entries.map(entry => ({
      start_time: entry.startDateTime,
      end_time: entry.endDateTime,
      is_deleted: false,
    }));

    setValue(`product_details[${index}].availability`, availabilityPayload);
    setValue('availability_type', scheduleType);
    clearErrors('scheduleSchema');
  };

  useEffect(() => {
    if (scheduleEntries.length > 0) {
      updateAvailability(scheduleEntries);
    }
  }, [scheduleEntries]);

  const handleAddSchedule = () => {
    if (dates && times && dates[0] && dates[1] && times[0] && times[1]) {
      const startDateTime = `${dates[0].format('YYYY-MM-DD')}T${times[0].format('HH:mm:ss')}`;
      const endDateTime = `${dates[1].format('YYYY-MM-DD')}T${times[1].format('HH:mm:ss')}`;

      const newEntry: ScheduleEntry = {
        key: scheduleEntries.length + 1,
        startDateTime,
        endDateTime,
      };

      setScheduleEntries(prevEntries => [...prevEntries, newEntry]);
      resetFields();
    }
  };

  const handleDeleteEntry = (key: number) => {
    setScheduleEntries(prevEntries =>
      prevEntries.filter(entry => entry.key !== key),
    );
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <div className="mr-5 md:w-1/5">
          <div className="flex h-14 items-center rounded-r-md bg-light-gray">
            <label className="items-center p-4 font-airbnb text-[16px] font-bold">
              {t('ProductListing.schedualing')}
            </label>
          </div>
        </div>
        <div className="mr-5 w-full md:w-4/5">
          <div className="mt-4 flex flex-col">
            <div className="flex gap-44 ">
              <label className="text-md font-medium">
                {t('ProductListing.select-available-date')}
                <span className="text-red-500">*</span>
              </label>
              <label className="text-md font-medium">
                {t('ProductListing.select-available-time')}
                <span className="text-red-500">*</span>
              </label>
            </div>

            {scheduleEntries.length > 0 && (
              <SchedulingTable
                data={scheduleEntries}
                onDelete={handleDeleteEntry}
              />
            )}
          </div>

          <div className="mt-4 flex flex-col md:flex-row md:space-x-4">
            <div>
              <RangePicker
                value={dates}
                placeholder={['Start Date', 'End Date']}
                className="w-full"
                onChange={dates =>
                  setDates(dates as [Dayjs | null, Dayjs | null])
                }
              />
            </div>
            <div>
              <TimePicker.RangePicker
                value={times}
                className="w-full"
                format="h:mm A"
                placeholder={['Start Time', 'End Time']}
                onChange={times =>
                  setTimes(times as [Dayjs | null, Dayjs | null])
                }
              />
            </div>
            <div className="mx-5 mb-4">
              <button
                type="button"
                className="mb-6 cursor-pointer border-none bg-transparent "
                onClick={handleAddSchedule}
                aria-label={'ProductListing.add'}
              >
                <img src={AddIcon} alt="Add Schedule" width={40} height={40} />
              </button>
            </div>
          </div>

          {errors?.scheduleSchema && (
            <p className="text-red-600">Time is required</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Scheduling;
