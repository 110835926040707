import PlaceholderImage from 'assets/img/placeholderImage.png';
import dayjs, { type Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Addon,
  ProductTypeFormData,
  ProductVariant,
  VariantValue,
} from 'types/entities';

import DatePickerCart from './DatePickerCart';

interface AddToCartProps {
  products: ProductTypeFormData;
  activeTabIndex: number;
}

interface ProductDetail {
  pricing?: {
    base_price: number;
  };
  variants?: ProductVariant[];
  addons?: Addon[];
  availability?: { start_datetime: string; end_datetime: string }[];
}

const AddToCart: React.FC<AddToCartProps> = ({ products, activeTabIndex }) => {
  const { t } = useTranslation();
  const [productDetail, setProductDetail] = useState<ProductDetail>({});
  const [checkInDate, setCheckInDate] = useState<string | null>(null);
  const [isCheckInPickerOpen, setIsCheckInPickerOpen] = useState(false);
  const [isCheckOutPickerOpen, setIsCheckOutPickerOpen] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState<string | null>(null);
  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(price);
  };
  useEffect(() => {
    setProductDetail(products?.product_details?.[activeTabIndex] || {});
  }, [activeTabIndex, products?.product_details]);

  const calculateTotal = (): number => {
    const basePrice = productDetail.pricing?.base_price ?? 0;
    const variantPrice =
      productDetail.variants?.reduce(
        (total, variant) =>
          total +
          (variant?.variants_values?.reduce(
            (variantTotal, variantValue) =>
              variantTotal + (variantValue?.price ?? 0),
            0,
          ) ?? 0),
        0,
      ) ?? 0;

    const addOnsTotal =
      productDetail.addons?.reduce((total, addon) => total + addon.price, 0) ??
      0;

    return basePrice + variantPrice + addOnsTotal;
  };

  const getImageSrc = (src?: string) => src || PlaceholderImage;

  const availabilityDates = productDetail?.availability || [];
  const disabledDates = (date: Dayjs) => {
    const today = dayjs().startOf('day');
    return date.isBefore(today);
  };

  const disabledDatesCheckIn = (current: any) => {
    if (!checkInDate) return false;
    return current.isBefore(checkInDate, 'day');
  };

  const basePrice = productDetail.pricing?.base_price ?? 0;

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <h2 className="mt-8 text-lg font-bold text-stone-800">
          {' '}
          {t('product_detail.Price')}
        </h2>
        <h2 className="mt-8 text-lg font-bold text-stone-800">
          {formatPrice(basePrice)}
          <span className="text-[12px] font-normal">
            /{products?.availability_type}
          </span>
        </h2>
      </div>

      <DatePickerCart
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        setCheckInDate={setCheckInDate}
        setCheckOutDate={setCheckOutDate}
        isCheckInPickerOpen={isCheckInPickerOpen}
        setIsCheckInPickerOpen={setIsCheckInPickerOpen}
        isCheckOutPickerOpen={isCheckOutPickerOpen}
        setIsCheckOutPickerOpen={setIsCheckOutPickerOpen}
        availabilityDates={availabilityDates}
        disabledDates={disabledDates}
        disabledDatesCheckIn={disabledDatesCheckIn}
      />

      <hr className="mt-8 w-full" />

      {productDetail.variants?.length ? (
        <>
          <div className="mt-4 flex items-center justify-between text-lg font-medium text-stone-800">
            {t('product_detail.variations')}
          </div>
          <h4 className="text-[12px] font-normal text-gray-400">
            {t('product_detail.Total_Options')}:{' '}
            {productDetail.variants?.length}
          </h4>
          {productDetail.variants.map((variant: ProductVariant) => (
            <div key={variant.id} className="mx-1 my-2">
              <div className="mb-2 text-base font-semibold">{variant.name}</div>
              <div className="-m-2 flex flex-wrap">
                {variant.variants_values?.map((variantValue: VariantValue) => (
                  <div key={variantValue.uuid} className="w-1/2 p-2">
                    <div className="flex flex-row items-start rounded-lg p-2">
                      <div className="ml-2 flex w-full flex-col justify-between rounded-lg border border-gray-400 font-semibold shadow-md">
                        <div className="flex flex-row items-center justify-between gap-4 p-2">
                          <p className="text-[16px]">{variantValue.value}</p>
                          <p className="text-[16px]">
                            {formatPrice(variantValue.price)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr className="my-4 w-full" />
            </div>
          ))}
        </>
      ) : (
        <div />
      )}

      {productDetail.addons?.length ? (
        <>
          <div className="mt-4 flex items-center justify-between text-lg font-medium text-stone-800">
            {t('product_detail.Add_Ons')}
          </div>
          <h4 className="text-base font-normal text-gray-400">
            {t('product_detail.Item_subtotal')}: {productDetail.addons?.length}
          </h4>
          {productDetail.addons.map((addon: Addon) => (
            <div key={addon.uuid} className="mx-1 my-2">
              <div className="flex flex-row items-start rounded-lg p-2">
                <div className="h-[80px] w-[116px] overflow-hidden rounded border object-cover shadow-sm">
                  <img
                    src={getImageSrc(addon.image)}
                    alt="add Icon"
                    className="object-cover"
                  />
                </div>
                <div className="ml-2 flex w-full flex-col justify-between">
                  <p className="text-[14px] font-normal text-gray-700">
                    {addon.name.charAt(0).toUpperCase() + addon.name.slice(1)}
                  </p>
                  <p className="text-[14px] font-normal text-gray-700">
                    {formatPrice(addon.price)}
                  </p>
                </div>
              </div>
              <hr className="mt-5" />
            </div>
          ))}
        </>
      ) : (
        <div className="h-30px" />
      )}

      <div className="py-4 text-base font-bold text-black">
        <div className="flex justify-between">
          <p>{t('product_detail.Item_subtotal')}</p>
          <p>{formatPrice(basePrice)}</p>
        </div>
        <div className="flex justify-between">
          <p>{t('product_detail.Variants_total')}</p>
          <p>
            {formatPrice(
              productDetail.variants?.reduce(
                (total, variant) =>
                  total +
                  (variant?.variants_values?.reduce(
                    (variantTotal, variantValue) =>
                      variantTotal + (variantValue?.price ?? 0),
                    0,
                  ) ?? 0),
                0,
              ) ?? 0,
            )}
          </p>
        </div>

        <div className="flex justify-between">
          <p>{t('product_detail.Add_Ons_total')}</p>
          <p>
            {formatPrice(
              productDetail.addons?.reduce(
                (total, addon) => total + addon.price,
                0,
              ) ?? 0,
            )}
          </p>
        </div>

        <div className="flex justify-between">
          <p>{t('product_detail.Total')}</p>
          <p>{formatPrice(calculateTotal())}</p>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
