'use client';

import type { InputProps as AntdInputProps } from 'antd';
import { Checkbox as AntdCheckbox, Input as AntdInput } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface CustomInputProps extends AntdInputProps {
  icon?: React.ReactNode;
  containerClassName?: string;
  inputClassName?: string;
  placeholder?: string;
  height?: string;
  iconClassName?: string;
  name: string;
  type?: 'text' | 'checkbox' | 'number';
  valueAsNumber?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  icon,
  placeholder,
  inputClassName = '',
  containerClassName = '',
  height = '50px',
  iconClassName = '',
  name,
  type = 'text',
  ...props
}) => {
  const padding = clsx(icon ? 'pl-14' : 'pl-4');
  const { control } = useFormContext() ?? {};

  if (!control) return null;

  return (
    <div className={`relative ${containerClassName}`}>
      {icon && <div className={`absolute left-5 ${iconClassName}`}>{icon}</div>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          if (type === 'checkbox') {
            return (
              <AntdCheckbox
                className={`w-full ${inputClassName}`}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
              >
                {placeholder}
              </AntdCheckbox>
            );
          }

          return (
            <AntdInput
              className={`w-full rounded-md border-2 ${padding} ${inputClassName}`}
              placeholder={placeholder}
              aria-label={placeholder}
              style={{ height }}
              {...field}
              {...props}
              onChange={e => {
                const value =
                  type === 'number'
                    ? parseFloat(e.target.value)
                    : e.target.value;
                field.onChange(value);
              }}
              type={type === 'number' ? 'number' : 'text'}
            />
          );
        }}
      />
    </div>
  );
};

export default CustomInput;
