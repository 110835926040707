import { SkeletonProductDetails } from 'app/components/SkeletonProductDetails.tsx';
import { useGetProductDetails } from 'hooks/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import ProductDetails from '../ProductDetail';

const ProductDetailTab = () => {
  const { id } = useParams<{ id: string }>();
  const { data: productData, error, isLoading } = useGetProductDetails(id!);
  const { t } = useTranslation();
  if (isLoading) return <SkeletonProductDetails />;
  if (error) return <div>Error: {error.message}</div>;
  if (!productData) return <div>No product found</div>;

  const productDetails = productData.product_details;
  const tabs = productDetails
    ? productDetails.map((detail: any) => ({
        id: `tab-${detail.product_type.uuid}`,
        label: detail.product_type.title,
        content: <div key={detail.product_type.uuid} />,
      }))
    : [];

  return (
    <div className="rounded-xl border border-gray-300 bg-white">
      <div className="px-7 pt-7">
        {' '}
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <div className="text-lg font-bold">
              {t('ProductListing.productDetails')}
            </div>
            <div className="flex flex-row">
              <Link to="/">{t('addProduct.dashboard')}</Link>
              <span className="px-2">/</span>
              <div className="text-blue-800">
                <Link to="/view-listing">
                  {t('ProductListing.productDetails')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <div className="mr-20 flex w-36 items-center justify-center rounded-[50px] bg-[#0822f9]/20">
              {productData.status}
            </div>
          </div>
        </div>
      </div>{' '}
      <ProductDetails
        product={productData}
        tabs={tabs.length > 1 ? tabs : []}
      />
    </div>
  );
};

export default ProductDetailTab;
