import SearchInput from 'app/components/SearchInput';
import { useSearchAddons } from 'hooks/queries';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import searchIcon from './../../../assets/icons/searchIcon.svg';

interface AddonSearchFilterProps {
  onAddAddon: (addon: any) => void;
}

type DropdownRef = HTMLDivElement | null;

const AddonSearchFilter: React.FC<AddonSearchFilterProps> = ({
  onAddAddon,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data: searchData } = useSearchAddons(searchValue);
  const [allAddOns, setAllAddOns] = useState<any[]>([]);
  const dropdownRef = useRef<DropdownRef>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (Array.isArray(searchData)) {
      setAllAddOns(searchData);
    }
  }, [searchData]);

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredAddOns = searchValue
    ? allAddOns.filter((addon: any) =>
        addon.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    : allAddOns;

  const handleDropdownItemClick = (addon: any) => {
    onAddAddon(addon);
    setSearchValue('');
    closeDropdown();
  };

  return (
    <div className="mt-10">
      <SearchInput
        value={searchValue}
        onChange={value => {
          setSearchValue(value);
          if (!dropdownVisible) {
            setDropdownVisible(true);
          }
        }}
        placeholder={t('ProductListing.placeholder-add-ons')}
        iconPosition="right"
        icon={searchIcon}
        containerClassName="bg-white border border-gray-300 rounded-lg shadow-lg w-[380px] transition-all duration-300 ease-in-out focus-within:border-blue-400"
        inputClassName="py-2 pl-10 pr-3 text-sm cursor-pointer w-full bg-light-gray-500 border-none rounded-lg focus:ring-2 focus:ring-blue-400"
      />

      {dropdownVisible && (
        <div
          className="scrollbar-hidden absolute z-10 mt-2 max-h-[200px] w-[380px] overflow-y-auto rounded-lg border border-gray-300 bg-white shadow-lg"
          ref={dropdownRef}
        >
          {allAddOns.length > 0 ? (
            filteredAddOns.map((addon: any) => (
              <div
                key={addon.id}
                className="cursor-pointer p-2 transition-all duration-200 ease-in-out hover:bg-gray-100"
                onClick={() => handleDropdownItemClick(addon)}
              >
                <div className="flex items-center gap-3">
                  <img
                    src={addon.image}
                    className="size-12 rounded-full border border-gray-300 object-cover"
                  />
                  <div className="grow">
                    <div className="text-sm font-semibold text-gray-700">
                      {addon.name}
                    </div>
                    <div className="text-xs text-gray-500">${addon.price}</div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2 text-center text-gray-500">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddonSearchFilter;
