'use client';

import CustomInput from 'app/components/CustomInput/customInput';
import Uploader from 'app/components/Uploader';
import { useUploadImage } from 'hooks/queries';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShowToastNotification } from 'utils/ToastNotification/toastNotification';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import AddonsTable from './AddOnsAndSpecficationTable';
import AddonSearchFilter from './AddonsSearch';

interface AddOnsProps {
  setValue: ReturnType<typeof useFormContext>['setValue'];
  watch: ReturnType<typeof useFormContext>['watch'];
  index: number;
  errors: any;
  register: any;
}
const AddOns: React.FC<AddOnsProps> = ({
  watch,
  setValue,
  index,
  errors,
  register,
}) => {
  const { getValues } = useFormContext();
  const { t } = useTranslation();

  const addOnsData = watch(`product_details[${index}].addons`) || [];
  const [imagePreviews, setImagePreviews] = useState<{ [key: number]: string }>(
    {},
  );
  const [uploadedImage, setUploadedImage] = useState<string>();
  // const [searchValue, setSearchValue] = useState('');

  const { mutate: uploadImage } = useUploadImage();

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('files', file);

    uploadImage(formData, {
      onSuccess: (data: { uploaded_documents: { url: any }[] }) => {
        const imageUrl = data?.uploaded_documents?.[0]?.url;
        if (imageUrl) {
          setValue(`product_details[${index}].addons.image`, imageUrl);
          setUploadedImage(imageUrl);
        }
      },
    });
  };

  const addAddOn = () => {
    const newAddOn = {
      name: getValues(`product_details[${index}].addons.name`),
      price: getValues(`product_details[${index}].addons.price`),
      description: getValues(`product_details[${index}].addons.description`),
      quantity: getValues(`product_details[${index}].addons.quantity`),
      image: getValues(`product_details[${index}].addons.image`),
    };
    if (!newAddOn.name) {
      ShowToastNotification('Addon.Name.Required', 'error');
      return;
    }
    if (!newAddOn.price) {
      ShowToastNotification('Addon.Price.Required', 'error');
      return;
    }

    if (!newAddOn.image) {
      ShowToastNotification('Addon.Image.Required', 'error');
      return;
    }
    if (!newAddOn.description) {
      ShowToastNotification('Addon.Description.Required', 'error');
      return;
    }

    const updatedAddOns = [...addOnsData, newAddOn];
    setValue(`product_details[${index}].addons`, updatedAddOns);

    if (uploadedImage) {
      setImagePreviews((prev: any) => ({
        ...prev,
        [updatedAddOns.length - 1]: uploadedImage,
      }));
    }

    setValue(`product_details[${index}].addons.name`, '');
    setValue(`product_details[${index}].addons.price`, '');
    setValue(`product_details[${index}].addons.description`, '');
    setValue(`product_details[${index}].addons.quantity`, '');
    setUploadedImage('');
    ShowToastNotification('Addon.Added.Successfully');
  };

  // const handleSearchChange = (value: string) => {
  //   setSearchValue(value);
  // };

  const removeAddOn = (itemIndex: number) => {
    const updatedAddOns = addOnsData.filter(
      (_: any, i: number) => i !== itemIndex,
    );
    setValue(`product_details[${index}].addons`, updatedAddOns);
    ShowToastNotification('Addon.Deleted');

    setImagePreviews(prev => {
      const updatedPreviews = { ...prev };
      delete updatedPreviews[itemIndex];
      return updatedPreviews;
    });
  };

  return (
    <div className="mt-5 h-auto w-full">
      <div className="flex">
        <div className="mt-12 flex h-[55px] w-1/5 rounded-r-xl bg-light-gray ">
          <h1 className="items-center p-4 font-airbnb text-[16px] font-bold">
            {t('ProductListing.add_ons')}
          </h1>
        </div>
        {/* <SearchInput
          value={searchValue}
          icon={searchIcon}
          placeholder="Search for addons"
          onChange={handleSearchChange}
          iconPosition="left"
          containerClassName="mt-10 ml-10 "
          inputClassName="text-[14px] w-[25rem] bg-gray-100"
        /> */}
        <div className="ml-10 p-4">
          <AddonSearchFilter onAddAddon={addAddOn} />
        </div>
      </div>

      <div className="mr-5 flex flex-col md:flex-row">
        <div className="ml-3 mt-5 md:w-auto">
          <Uploader
            onImageUpload={handleImageUpload}
            previewSrc={uploadedImage}
            accept="image/*"
            width={160}
            alt="image"
            height={50}
            MainContainerHeight="96px"
            MainContainerWidth="128px"
            AddIconWidth={30}
            AddIconHeight={30}
            addIconClassName="left-16 top-0 justify-center"
          />
        </div>
        <div className="mt-8 flex flex-col md:ml-5 md:w-full md:flex-row">
          <div className="flex w-full flex-row flex-wrap justify-between">
            <div className="mb-4 w-full md:w-1/4">
              <label htmlFor={'name'} className="p-2 text-[14px] font-medium">
                {t('ProductListing.name')}
              </label>
              <CustomInput
                placeholder={t('ProductListing.name')}
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none border border-gray-200 shadow-sm w-full"
                height="36px"
                alt="name"
                name={`product_details[${index}].addons.name`}
              />
              {errors.product_details?.[index]?.addons?.name && (
                <p className="text-xs text-red-600 md:text-sm">
                  {errors.product_details[index].addons?.name.message}
                </p>
              )}
            </div>
            <div className="mb-4 ml-0 w-full md:w-1/4">
              <label htmlFor={'price'} className=" p-2 text-[14px] font-medium">
                {t('ProductListing.price')}
              </label>
              <CustomInput
                placeholder={t('ProductListing.price')}
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none border border-gray-200 shadow-sm w-full rounded-md"
                height="36px"
                type="number"
                alt="AddPrice"
                valueAsNumber
                name={`product_details[${index}].addons.price`}
              />
              {errors.product_details?.[index]?.addons?.price && (
                <p className="text-xs text-red-600 md:text-sm">
                  {errors.product_details[index].addons?.price.message}
                </p>
              )}
            </div>
            <div className="mb-4 w-full md:w-1/3">
              <label
                htmlFor={'description_note'}
                className=" p-2 text-[14px] font-medium"
              >
                {t('ProductListing.description')}
              </label>
              <textarea
                rows={1}
                className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={t('ProductListing.description')}
                // name={`product_details[${index}].addons.description`}
                {...register(`product_details[${index}].addons.description`)}
              />
            </div>

            <div className="my-6 ml-5 md:mt-6">
              <button
                type="button"
                className="cursor-pointer border-none bg-transparent p-0"
                onClick={addAddOn}
                aria-label={'ProductListing.add'}
              >
                <img
                  src={AddIcon}
                  alt={'ProductListing.add'}
                  width={40}
                  height={40}
                />
              </button>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-5 mt-4">
        {Array.isArray(addOnsData) && addOnsData.length > 0 ? (
          <AddonsTable
            data={addOnsData}
            removeAddOn={removeAddOn}
            index={index}
            imagePreviews={imagePreviews}
            register={register}
            getValues={getValues}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddOns;
