import { List } from 'antd';
import Config from 'constants/index';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { Geolocation } from 'types/entities';

interface FeatureContext {
  id: string;
  text: string;
}

interface Feature {
  id: string;
  place_name: string;
  context: FeatureContext[];
  geometry: {
    coordinates: [number, number];
  };
}

const LocationField: React.FC = () => {
  const accessToken = Config.mapboxgl;
  const {
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState<Feature[]>([]);
  const geolocation = watch('geolocation') as Geolocation;
  const [query, setQuery] = useState(geolocation?.address || '');

  useEffect(() => {
    const fetchCurrentLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async position => {
          const { latitude, longitude } = position.coords;
          const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`;
          const response = await fetch(url);
          const data = await response.json();

          if (data.features && data.features.length > 0) {
            const place = data.features[0];
            handleSuggestionClick(place);
          }
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    fetchCurrentLocation();
  }, []);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setQuery(input);
    if (input.length > 2) {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?access_token=${accessToken}&autocomplete=true`;
      const response = await fetch(url);
      const data = await response.json();
      setSuggestions(data.features);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (place: Feature) => {
    setQuery(place.place_name);
    setSuggestions([]);
    const [longitude, latitude] = place.geometry.coordinates;
    const postalCode =
      place.context.find(ctx => ctx.id.includes('postcode'))?.text || '0';
    const postalCodeNumber = isNaN(parseInt(postalCode, 10))
      ? 0
      : parseInt(postalCode, 10);
    const geolocationData: Geolocation = {
      location: `${latitude} ${longitude}`,
      address: place.place_name,
      town: place.context.find(ctx => ctx.id.includes('place'))?.text || '',
      city: place.context.find(ctx => ctx.id.includes('locality'))?.text || '',
      region: place.context.find(ctx => ctx.id.includes('region'))?.text || '',
      country:
        place.context.find(ctx => ctx.id.includes('country'))?.text || '',
      postal_code: postalCodeNumber,
    };
    setValue('geolocation', geolocationData);
    clearErrors('geolocation');
  };
  return (
    <div className="mb-5">
      <input
        type="text"
        id="search"
        name="address-1"
        value={query}
        onChange={handleInputChange}
        autoComplete="address-line1"
        placeholder="Search for an address"
        className="mb-2 block w-full rounded-lg border border-gray-300 p-2 text-[14px] font-normal text-gray-900 shadow-md focus:border-gray-500 focus:outline-none"
      />
      {suggestions.length > 0 && (
        <List className="z-10 rounded-md border border-gray-300 bg-white p-2 shadow-lg ">
          {suggestions.map(place => (
            <List.Item
              key={place.id}
              onClick={() => handleSuggestionClick(place)}
              className="w-full cursor-pointer"
            >
              {place.place_name}
            </List.Item>
          ))}
        </List>
      )}
      {errors?.title && (
        <p className="text-red-600"> {t('ProductListing.Location_required')}</p>
      )}
    </div>
  );
};

export default LocationField;
