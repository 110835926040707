import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { getTokenFromLocalStorage } from 'contexts/authContext';

import SessionExpireRedirect from './sessionExpire';

/**
 * Custom error class for handling response errors
 */
export class ResponseError extends Error {
  public response: AxiosResponse;

  constructor(response: AxiosResponse) {
    super(response.statusText);
    this.response = response;
  }
}

interface RequestConf extends AxiosRequestConfig {
  withAuthToken?: boolean;
}

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: any) => {
    if (axios.isAxiosError(error) && error.response) {
      SessionExpireRedirect(error.response.status);
      return Promise.reject(new ResponseError(error.response));
    }
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  (config: any) => {
    const modifiedConfig = { ...config };

    if (modifiedConfig.withAuthToken) {
      const accessToken = getTokenFromLocalStorage();
      modifiedConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return modifiedConfig;
  },

  (error: any) => {
    return Promise.reject(error);
  },
);

/**
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to Axios
 * @param  {boolean} [withAuthToken] The authToken we want to pass to “header request”
 *
 * @return {object}           The response data
 */
export const axiosService = async function axiosService<Response>(
  url: string,
  init?: RequestConf,
): Promise<Response> {
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    throw new Error('You are offline!');
  }

  const response = await axiosInstance(url, init);
  return response.data;
};
