import CustomInput from 'app/components/CustomInput/customInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Addon } from 'types/entities';

import DeleteIcon from './../../../assets/icons/deleteIcon.svg';

interface AddonsTableProps {
  data: Addon[];
  removeAddOn: (itemIndex: number) => void;
  index: number;
  imagePreviews?: { [key: number]: string };
  register: any;
  getValues: any;
}

const AddonsTable: React.FC<AddonsTableProps> = ({
  data,
  removeAddOn,
  index,
  imagePreviews,
  getValues,
}) => {
  const AddonData = data.filter(addon => !addon.is_deleted);
  const { t } = useTranslation();

  return (
    <div className="my-10 flex w-full justify-center overflow-x-auto">
      {AddonData.length === 0 ? (
        <div className="py-10 text-center text-gray-500">No data </div>
      ) : (
        <div className="min-w-full max-w-full">
          <table className="w-full border-collapse bg-gray-100 text-center">
            <thead>
              <tr className="text-center text-[12px] font-semibold">
                <th className="border-b-0 "> {t('ProductListing.image')}</th>
                <th className="border-b-0 px-4 py-2">
                  {' '}
                  {t('ProductListing.name')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {' '}
                  {t('ProductListing.price')}
                </th>
                <th className="border-b-0  px-4 py-2 text-center">
                  {t('ProductListing.quantity')}
                </th>
                <th className="border-b-0 p-2">
                  {' '}
                  {t('ProductListing.description')}
                </th>
                <th className="border-b-0 px-4 py-2">
                  {' '}
                  {t('ProductListing.action')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-light-gray-300 divide-y divide-gray-200 text-[12px] font-medium">
              {AddonData.map((item, tableIndex) => (
                <tr
                  key={item.id || tableIndex}
                  className="border-t border-gray-200 hover:bg-gray-100"
                >
                  <td className="ml-10  overflow-hidden rounded-l-lg object-cover py-1 ">
                    <img
                      src={imagePreviews?.[tableIndex] || item.image}
                      alt={`Preview of ${item.name}`}
                      width={60}
                      height={60}
                      className="ml-10  overflow-hidden border border-gray-200 object-cover"
                    />
                  </td>
                  <td className="px-6 py-1">{item.name}</td>
                  <td className="px-6 py-1">{item.price}</td>
                  <td className="px-6 py-1">
                    <CustomInput
                      type="number"
                      height="36px"
                      containerClassName="relative ml-6justify-center flex items-center "
                      inputClassName="focus:outline-none mt-2 w-full "
                      placeholder="1"
                      name={`product_details[${index}].addons[${tableIndex}].quantity`}
                    />
                  </td>
                  <td className="px-8 py-1">
                    <textarea
                      rows={1}
                      className="w-full rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      defaultValue={getValues(
                        `product_details[${index}].addons[${tableIndex}].description`,
                      )}
                      // {...register(
                      //   `product_details[${index}].addons.description`,
                      // )}
                    />
                  </td>
                  <td className="rounded-r-lg p-1">
                    <div
                      onClick={() => removeAddOn(tableIndex)}
                      className="flex items-center justify-center"
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={DeleteIcon}
                        alt={'delete'}
                        width={20}
                        height={20}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AddonsTable;
