import Input from 'app/components/Input';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ShowToastNotification } from 'utils/ToastNotification/toastNotification';

import AddIcon from '../../../assets/icons/addIcon2.svg';
import VariantsTable from './VariantsTable';

interface AttributesAndVariantsProps {
  register: ReturnType<typeof useFormContext>['register'];
  setValue: ReturnType<typeof useFormContext>['setValue'];
  watch: ReturnType<typeof useFormContext>['watch'];
  index: number;
  errors?: any;
}

const AttributesAndVariants: React.FC<AttributesAndVariantsProps> = ({
  setValue,
  index,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue: formSetValue } = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<any>>([]);
  const [variants, setVariants] = useState<any[]>([]);

  const handleSelectChange = (selected: MultiValue<any>) => {
    setSelectedOptions(selected);
  };

  const addAttribute = () => {
    const name = getValues(`product_details[${index}].variants.name`) || '';

    // if (!name.trim()) return;
    if (!name) {
      ShowToastNotification('Variants-Name-Required.error', 'error');
      return;
    }
    if (!selectedOptions.length) {
      ShowToastNotification('Variants-value-Required.error', 'error');
      return;
    }

    const newVariants = selectedOptions.map(option => ({
      name,
      variant_option: option.value,
      isStock: true,
      is_available_on_product: true,
      price: 0,
      variants_values: [{ value: option.value, is_deleted: false }],
    }));

    const updatedVariants = [...variants, ...newVariants];
    setVariants(updatedVariants);
    ShowToastNotification('Variant.Added');

    formSetValue(`product_details[${index}].variants`, updatedVariants);
    formSetValue(`product_details[${index}].variants.name`, '');
    setSelectedOptions([]);
  };

  const removeVariant = (variantIndex: number) => {
    const updatedVariants = variants.filter(
      (_, index) => index !== variantIndex,
    );

    setVariants(updatedVariants);
    formSetValue(`product_details[${index}].variants`, updatedVariants);
    ShowToastNotification('Variant.Deleted');
  };
  return (
    <>
      <div className="mt-24 flex flex-col items-center md:flex-row">
        <div className="flex h-[55px] rounded-r-lg bg-light-gray md:w-1/5">
          <h1 className="items-center justify-between p-4 font-airbnb text-[16px] font-bold">
            {t('ProductListing.variants')}
          </h1>
        </div>
        <div className="mr-5 w-full md:ml-5">
          <div className="mb-6 flex flex-col items-center md:flex-row">
            <div className="my-3 md:mb-0">
              <label
                htmlFor={t('ProductListing.name')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.name')} :
              </label>
              <Input
                placeholder={t('ProductListing.placeholder_name')}
                containerClassName="relative flex items-center"
                inputClassName="focus:outline-none w-full md:w-[197px] border border-gray-200 shadow-sm"
                height="38px"
                alt="name"
                name={`product_details[${index}].variants.name`}
              />
            </div>
            <div className="my-3 w-full md:mb-0 md:ml-2 md:w-[400px]">
              <label
                htmlFor={t('ProductListing.value')}
                className="p-2 font-airbnb text-[14px] font-medium"
              >
                {t('ProductListing.value')}:
              </label>
              <CreatableSelect
                isMulti
                placeholder={t('ProductListing.select-variants')}
                isClearable
                options={[]}
                name={`product_details[${index}].variants.variants_values`}
                className="w-full rounded-lg border border-gray-300 shadow-sm placeholder:text-sm focus:outline-none"
                onChange={handleSelectChange}
                value={selectedOptions}
              />
              {/* {requiredErrorMessage && (
                <p className="text-sm text-red-500">{requiredErrorMessage}</p>
              )} */}
            </div>
            <div className="my-6 ml-5 md:mt-16">
              <button
                type="button"
                className="cursor-pointer border-none bg-transparent p-0"
                onClick={addAttribute}
                aria-label={'ProductListing.add'}
              >
                <img
                  src={AddIcon}
                  alt={'ProductListing.add'}
                  width={40}
                  height={40}
                />
              </button>{' '}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <VariantsTable
          variants={variants}
          onRemoveVariant={removeVariant}
          setValue={setValue}
          index={index}
        />
      </div>
    </>
  );
};

export default AttributesAndVariants;
