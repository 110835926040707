import 'react-quill/dist/quill.snow.css';

import Input from 'app/components/Input';
import { quillFormats, quillModules } from 'constants/quill';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

interface GeneralInformationProps {
  register: any;
  errors: any;
  control: any;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  errors,
  control,
}) => {
  const { t } = useTranslation();
  return (
    <div className="h-auto w-full">
      <label className="mt-6">
        {t('ProductListing.title')}
        <span className="text-red-600">*</span>
      </label>
      <Input
        placeholder={t('ProductListing.title')}
        containerClassName="relative mt-2 pb-3 flex items-center mb-2"
        inputClassName="focus:outline-none"
        alt="title"
        height="60px"
        name="title"
        aria-label="title"
        // {...register('title')}
      />
      {errors?.title && (
        <p className="text-red-600"> {t('ProductListing.title_error')}</p>
      )}

      <label className="mt-6">
        {t('ProductListing.description')}
        <span className="text-red-600">*</span>
      </label>
      <div className="size-full min-h-7">
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <ReactQuill
              value={field.value}
              onChange={field.onChange}
              modules={quillModules}
              formats={quillFormats}
              placeholder={t('ProductListing.description_placeholder')}
              className="mt-2 h-full min-h-7 bg-white"
            />
          )}
        />
      </div>
      {errors?.description && (
        <p className="text-red-600">{t('ProductListing.description_error')}</p>
      )}
    </div>
  );
};

export default GeneralInformation;
